<template>
  <div
    v-if="structure"
    class="row"
  >
    <div class="five wide column">
      <div class="ui attached secondary segment">
        <h1 class="ui center aligned header ellipsis">
          <img
            v-if="structure.geom_type === 'point'"
            class="ui medium image"
            src="@/assets/img/marker.png"
          >
          <img
            v-if="structure.geom_type === 'linestring'"
            class="ui medium image"
            src="@/assets/img/line.png"
          >
          <img
            v-if="structure.geom_type === 'polygon'"
            class="ui medium image"
            src="@/assets/img/polygon.png"
          >
          {{ structure.title }}
        </h1>
      </div>
      <div class="ui attached segment">
        <div class="ui basic segment">
          <div class="ui horizontal tiny statistic">
            <div
              :class="{ active: featuresLoading }"
              class="ui inverted dimmer"
            >
              <div class="ui text loader">
                Récupération des signalements en cours...
              </div>
            </div>
            <div class="value">
              {{ features_count }}
            </div>
            <div class="label">
              Signalement{{ features.length > 1 ? "s" : "" }}
            </div>
          </div>

          <h3 class="ui header">
            Champs
          </h3>
          <div class="ui divided list">
            <div
              v-for="(field, index) in orderedCustomFields"
              :key="field.name + index"
              class="item"
            >
              <div class="right floated content">
                <div class="description">
                  {{ field.field_type }}
                </div>
              </div>
              <div class="content">
                {{ field.label }} ({{ field.name }})
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ui bottom attached secondary segment">
        <div
          v-if="permissions.can_create_feature"
          class="ui styled accordion"
        >
          <div
            :class="['title', { active: showImport }]"
            @click="toggleShowImport"
          >
            <i class="dropdown icon" />
            Importer des signalements
          </div>
          <div :class="['content', { active: showImport }]">
            <div
              id="form-import-features"
              class="ui form"
              :class="loadingImportFile ? 'loading' : ''"
            >
              <div class="field">
                <label
                  class="ui icon button ellipsis"
                  for="json_file"
                >
                  <i class="file icon" />
                  <span class="label">{{ fileToImport.name }}</span>
                </label>
                <input
                  id="json_file"
                  type="file"
                  accept="application/json, .json, .geojson"
                  style="display: none"
                  name="json_file"
                  @change="onFileChange"
                >
              </div>

              <router-link
                v-if="
                  IDGO &&
                    permissions &&
                    permissions.can_create_feature
                "
                :to="{
                  name: 'catalog-import',
                  params: {
                    slug,
                    feature_type_slug: $route.params.feature_type_slug
                  },
                }"
                class="ui icon button import-catalog"
              >
                Importer les signalements à partir de {{ CATALOG_NAME|| 'IDGO' }}
              </router-link>
              <div
                v-if="$route.params.geojson"
                class="ui button import-catalog basic active teal no-hover"
              >
                Ressource {{ $route.params.geojson.name }}
              </div>
              <ul
                v-if="importError"
                class="errorlist"
              >
                <li>
                  {{ importError }}
                </li>
              </ul>
              <button
                :disabled="fileToImport.size === 0 && !$route.params.geojson"
                class="ui fluid teal icon button"
                @click="importGeoJson"
              >
                <i class="upload icon" /> Lancer l'import
              </button>
              <ImportTask
                v-if="importFeatureTypeData && importFeatureTypeData.length"
                :data="importFeatureTypeData"
                :reloading="reloadingImport"
              />
            </div>
          </div>
        </div>
        <div class="ui styled accordion">
          <div
            :class="['title', { active: !showImport }]"
            @click="toggleShowImport"
          >
            <i class="dropdown icon" />
            Exporter les signalements
          </div>
          <div :class="['content', { active: !showImport }]">
            <p>
              Vous pouvez télécharger tous les signalements qui vous sont
              accessibles.
            </p>
            <button
              type="button"
              class="ui fluid teal icon button"
              @click="exportFeatures"
            >
              <i class="download icon" /> Exporter
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="nine wide column">
      <h3 class="ui header">
        Derniers signalements
      </h3>
      <div
        :class="{ active: featuresLoading }"
        class="ui inverted dimmer"
      >
        <div class="ui text loader">
          Récupération des signalements en cours...
        </div>
      </div>
      <div
        v-if="
          importFeatureTypeData &&
            importFeatureTypeData.length &&
            importFeatureTypeData.some((el) => el.status === 'pending')
        "
        class="ui message info"
      >
        <p>
          Des signalements sont en cours d'import. Pour suivre le statut de
          l'import, cliquez sur "Importer des Signalements".
        </p>
      </div>
      <div
        v-else-if="waitMessage"
        class="ui message info"
      >
        <p>
          L'import des signalements a été lancé.
          Vous pourrez suivre le statut de l'import dans quelques instants...
        </p>
      </div>
      <div
        v-for="(feature, index) in lastFeatures"
        :key="feature.feature_id + index"
        class="ui small header"
      >
        <span
          v-if="feature.status === 'archived'"
          data-tooltip="Archivé"
        >
          <i class="grey archive icon" />
        </span>
        <span
          v-else-if="feature.status === 'pending'"
          data-tooltip="En attente de publication"
        >
          <i class="teal hourglass outline icon" />
        </span>
        <span
          v-else-if="feature.status === 'published'"
          data-tooltip="Publié"
        >
          <i class="olive check icon" />
        </span>
        <span
          v-else-if="feature.status === 'draft'"
          data-tooltip="Brouillon"
        >
          <i class="orange pencil alternate icon" />
        </span>
        <router-link
          :to="{
            name: 'details-signalement',
            params: {
              slug,
              slug_type_signal: $route.params.feature_type_slug,
              slug_signal: feature.feature_id,
            },
          }"
        >
          {{ feature.title || feature.feature_id }}
        </router-link>
        <div class="sub header">
          <div>
            {{
              feature.description
                ? feature.description.substring(0, 200)
                : "Pas de description disponible"
            }}
          </div>
          <div>
            [ Créé le {{ feature.created_on | formatDate }}
            <span v-if="$store.state.user">
              par {{ feature.display_creator }}</span>
            ]
          </div>
        </div>
      </div>
      <router-link
        v-if="project"
        :to="{ name: 'liste-signalements', params: { slug } }"
        class="ui right labeled icon button margin-25"
      >
        <i class="right arrow icon" />
        Voir tous les signalements
      </router-link>
      <router-link
        v-if="permissions.can_create_feature"
        :to="{
          name: 'ajouter-signalement',
          params: { slug_type_signal: structure.slug },
        }"
        class="ui icon button button-hover-green margin-25"
      >
        Ajouter un signalement
      </router-link>
      <br>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import ImportTask from '@/components/ImportTask';
import featureAPI from '@/services/feature-api';

import { fileConvertSizeToMo } from '@/assets/js/utils';

export default {
  name: 'FeatureTypeDetail',
  
  components: {
    ImportTask: ImportTask,
  },

  filters: {
    formatDate(value) {
      let date = new Date(value);
      date = date.toLocaleString().replace(',', ' à');
      return date.substr(0, date.length - 3); //* quick & dirty way to remove seconds from date
    },
  },

  props: {
    geojson: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      importError: '',
      fileToImport: {
        name: 'Sélectionner un fichier GeoJSON ...',
        size: 0,
      },
      showImport: false,
      slug: this.$route.params.slug,
      featuresLoading: true,
      loadingImportFile: false,
      waitMessage: false,
      reloadingImport: false,
    };
  },

  computed: {
    ...mapGetters([
      'permissions'
    ]),
    ...mapGetters('projects', [
      'project'
    ]),
    ...mapState([
      'reloadIntervalId',
      'configuration',
    ]),
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature', [
      'features',
      'features_count'
    ]),
    ...mapState('feature_type', [
      'feature_types',
      'importFeatureTypeData'
    ]),
    CATALOG_NAME() {
      return this.configuration.VUE_APP_CATALOG_NAME;
    },
    IDGO() {
      return this.$store.state.configuration.VUE_APP_IDGO;
    },
    structure: function () {
      if (Object.keys(this.feature_types).length) {
        let st = this.feature_types.find(
          (el) => el.slug === this.$route.params.feature_type_slug
        );
        if (st) return st;
      }
      return {};
    },

    feature_type_features: function () {
      if (this.features.length)
        return this.features.filter(
          (el) => el.feature_type.slug === this.$route.params.feature_type_slug
        );
      return {};
    },

    lastFeatures: function () {
      if (this.feature_type_features.length)
        return this.feature_type_features.slice(0, 5);
      return [];
    },

    orderedCustomFields() {
      if (Object.keys(this.structure).length)
        return [...this.structure.customfield_set].sort(
          (a, b) => a.position - b.position
        );
      return {};
    },
  },

  watch: {
    structure(newValue) {
      if (newValue.slug) {
        this.GET_IMPORTS({
          feature_type: this.$route.params.feature_type_slug
        });
      }
    },

    importFeatureTypeData: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.some(el => el.status === 'pending')) {
          setTimeout(() => {
            this.reloadingImport = true;
            this.GET_IMPORTS({
              feature_type: this.$route.params.feature_type_slug
            }).then(()=> {
              setTimeout(() => {
                this.reloadingImport = false;
              }, 1000);
            });
          }, this.$store.state.configuration.VUE_APP_RELOAD_INTERVAL);
        } else if (oldValue && oldValue.some(el => el.status === 'pending')) {
          this.getLastFeatures();
        }
      }
    },
  },

  created() {
    if (!this.project) {
      this.$store.dispatch('projects/GET_PROJECT', this.slug);
      this.$store.dispatch('projects/GET_PROJECT_INFO', this.slug);
    }
    this.$store.commit('feature/SET_FEATURES', []); //* empty remaining features in case they were in geojson format and will be fetch anyway
    this.getLastFeatures();
    this.SET_CURRENT_FEATURE_TYPE_SLUG(
      this.$route.params.feature_type_slug
    );
    if (this.$route.params.type === 'external-geojson') {
      this.showImport = true;
    }
  },

  methods: {
    ...mapMutations('feature_type', ['SET_CURRENT_FEATURE_TYPE_SLUG']),
    ...mapActions('feature_type', ['GET_IMPORTS']),
    ...mapActions('feature', ['GET_PROJECT_FEATURES']),
    toggleShowImport() {
      this.showImport = !this.showImport;
      if (this.showImport) {
        this.GET_IMPORTS({
          feature_type: this.$route.params.feature_type_slug
        });
      }
    },

    transformProperties(prop) {
      const type = typeof prop;
      const date = new Date(prop);
      if (type === 'boolean') {
        return 'boolean';
      } else if (Number.isSafeInteger(prop)) {
        return 'integer';
      } else if (
        type === 'string' &&
        ['/', ':', '-'].some((el) => prop.includes(el)) && // check for chars found in datestring
        date instanceof Date &&
        !isNaN(date.valueOf())
      ) {
        return 'date';
      } else if (type === 'number' && !isNaN(parseFloat(prop))) {
        return 'decimal';
      }
      return 'char'; //* string by default, most accepted type in database
    },

    checkJsonValidity(json) {
      this.importError = '';
      const fields = this.structure.customfield_set.map((el) => {
        return {
          name: el.name,
          field_type: el.field_type,
          options: el.options,
        };
      });
      for (const feature of json.features) {
        for (const { name, field_type, options } of fields) {
          if (name in feature.properties) {
            const fieldInFeature = feature.properties[name];
            const customType = this.transformProperties(fieldInFeature);
            //* if custom field value is not null, then check validity of field
            if (fieldInFeature !== null) {
              //* if field type is list, it's not possible to guess from value type
              if (field_type === 'list') {
                //*then check if the value is an available option
                if (!options.includes(fieldInFeature)) {
                  return false;
                }
              } else if (customType !== field_type) {
                //* check if custom field value match
                this.importError = `Le fichier est invalide: Un champ de type ${field_type} ne peut pas avoir la valeur [ ${fieldInFeature} ]`;
                return false;
              }
            }
          }
        }
      }
      return true;
    },

    onFileChange(e) {
      this.loadingImportFile = true;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.loadingImportFile = false;
        return;
      }

      let reader = new FileReader();
      reader.addEventListener('load', (e) => {
        // bypass json check for files larger then 10 Mo
        let jsonValidity;
        if (parseFloat(fileConvertSizeToMo(files[0])) <= 10) {
          jsonValidity = this.checkJsonValidity(JSON.parse(e.target.result));
        } else {
          jsonValidity = true;
        }

        if (jsonValidity) {
          this.fileToImport = files[0]; // todo : remove this value from state as it stored (first attempt didn't work)
          this.$store.commit(
            'feature_type/SET_FILE_TO_IMPORT',
            this.fileToImport
          );
        }
        this.loadingImportFile = false;
      });
      reader.readAsText(files[0]);
    },

    importGeoJson() {
      this.waitMessage = true;
      let payload = {
        slug: this.slug,
        feature_type_slug: this.$route.params.feature_type_slug,
      };
      if (this.$route.params.geojson) { //* import after redirection, for instance with data from catalog
        payload['geojson'] = this.$route.params.geojson;
      } else if (this.fileToImport.size > 0) { //* import directly from geojson
        payload['fileToImport'] = this.fileToImport;
      } else {
        this.importError = "La ressource n'a pas pu être récupéré.";
        return;
      }
      this.$store.dispatch('feature_type/SEND_FEATURES_FROM_GEOJSON', payload)
        .then(() => {
          this.waitMessage = false;
        });
    },

    exportFeatures() {
      const url = `${this.$store.state.configuration.VUE_APP_DJANGO_API_BASE}projects/${this.slug}/feature-type/${this.$route.params.feature_type_slug}/export/`;
      featureAPI.getFeaturesBlob(url).then((blob) => {
        if (blob) {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${this.project.title}-${this.structure.title}.json`;
          link.click();
          URL.revokeObjectURL(link.href);
        }
      });
    },
    async getLastFeatures(){
      const response = await 
      this.GET_PROJECT_FEATURES({
        project_slug:  this.slug,
        feature_type__slug : this.$route.params.feature_type_slug,
        ordering: '-created_on',
        limit: '5'
      });

      if (response) {
        this.featuresLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.margin-25 {
  margin: 0 0.25em 0.25em 0 !important;
}

.import-catalog {
  margin-bottom: 1em;
}

.no-hover {
  cursor: default;
}
</style>