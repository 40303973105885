export function fileConvertSize(aSize){
  aSize = Math.abs(parseInt(aSize, 10));
  const def = [[1, 'octets', 0], [1024, 'ko', 0], [1024*1024, 'Mo', 1], [1024*1024*1024, 'Go', 2], [1024*1024*1024*1024, 'To', 2]];
  for (let i=0; i<def.length; i++) {
    if (aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(def[i-1][2]) + ' ' + def[i-1][1];
  }
}

export function fileConvertSizeToMo(aSize){
  aSize = Math.abs(parseInt(aSize, 10));
  const def = [1024*1024, 'Mo', 1];
  return (aSize/def[0]).toFixed(def[2]);
}
