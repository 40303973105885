<template>
  <div id="table-imports">
    <table class="ui collapsing celled table">
      <thead>
        <tr>
          <th>Fichiers importés</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="importFile in data"
          :key="importFile.created_on"
        >
          <td>
            <h4 class="ui header align-right">
              <div :data-tooltip="importFile.geojson_file_name">
                {{ importFile.geojson_file_name | subString }}
                <div class="sub header">
                  ajouté le {{ importFile.created_on | setDate }}
                </div>
              </div>
            </h4>
          </td>

          <td>
            <span
              v-if="importFile.infos"
              :data-tooltip="importFile.infos"
              class="ui icon margin-left"
            >
              <i
                v-if="importFile.status === 'processing'"
                class="orange hourglass half icon"
              />
              <i
                v-else-if="importFile.status === 'finished'"
                class="green check circle outline icon"
              />
              <i
                v-else-if="importFile.status === 'failed'"
                class="red x icon"
              />
              <i
                v-else
                class="red ban icon"
              />
            </span>
            <span
              v-if="importFile.status === 'pending'"
              data-tooltip="Statut en attente. Clickez pour rafraichir."
            >
              <i
                :class="['orange icon', ready && !reloading ? 'sync' : 'hourglass half rotate']"
                @click="fetchImports()"
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  filters: {
    setDate: function (value) {
      let date = new Date(value);
      let d = date.toLocaleDateString('fr', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      return d;
    },
    subString: function (value) {
      return value.substring(0, 27) + '..';
    },
  },

  props: {
    data: {
      type: Array,
      default: null,
    },
    reloading: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      open: false,
      ready: true,
    };
  },

  computed: {
    ...mapState('feature', ['features']),
  },

  watch: {
    data(newValue) {
      if (newValue) {
        this.ready = true;
      }
    },
  },

  methods: {
    fetchImports() {
      this.$store.dispatch(
        'feature_type/GET_IMPORTS', {
          feature_type: this.$route.params.feature_type_slug
        });
      this.$store.dispatch('feature/GET_PROJECT_FEATURES', {
        project_slug:  this.$route.params.slug,
        feature_type__slug:  this.$route.params.feature_type_slug
      });
      //* show that the action was triggered, could be improved with animation (doesn't work)
      this.ready = false;
    },
  },
};
</script>

<style scoped>
.sync {
  cursor: pointer;
}

#table-imports {
  padding-top: 1em;
}

i.icon {
  width: 20px !important;
  height: 20px !important;
}

.rotate {
  -webkit-animation:spin 1s linear infinite;
  -moz-animation:spin 1s linear infinite;
  animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    /* top: 6px; */
    left: 6px;
    width: 25%;
    padding-right: 10px;
    white-space: "break-spaces";
  }

  /*
	Label the data
	*/
  td:nth-of-type(1):before {
    content: "Fichiers importés";
  }
  td:nth-of-type(2):before {
    content: "Statut";
  }

  .align-right {
    text-align: right;
  }
  .margin-left {
    margin-left: 94%;
  }
}
</style>